//imported file
import React from "react";
import { NavLink } from "react-router-dom";
//404 component
const NotFound = () => {
  return (
    <div className="text-center container" style={{ marginTop: "100px" }}>
      <h1 className="font-bold d-block d-md-none" style={{ fontSize: "100px" }}>
        404
      </h1>
      <h1 className="font-bold d-none d-md-block" style={{ fontSize: "300px" }}>
        404
      </h1>
      <h3 className="font-bold" style={{ fontSize: "50px" }}>
        Oops! The requested page is not available.
      </h3>
      <br />
      <NavLink to="/">
        <button className="button button-a button-big ">Go to Homepage</button>
      </NavLink>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default NotFound;
