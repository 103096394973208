import React from "react";

const Blogs = () => {
  return (
    <div className="text-center" style={{ height: "48vh", marginTop: "200px" }}>
      <h1>Blogs are coming soon!</h1>
    </div>
  );
};

export default Blogs;
