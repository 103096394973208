import React from "react";

const FloralCare = () => {
  return (
    <div className="container">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="row text-center">
        <div className="col-12 col-md-6">
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/Hps0pq1/Screenshot-7.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/0mPN4zX/Screenshot-8.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/zfCZXWW/Screenshot-10.png"
              alt=""
            />
          </div>
          <br />
        </div>
        <div className="col-12 col-md-6">
          .
          <h2>
            Project Name: Floral Care – A Frontend Hospital Management Project
          </h2>
          <h3>Details</h3>
          <p>
            <ul className="text-start">
              <li>
                This is a tourism service project.
                <br />
              </li>

              <li>
                Anyone can book their required healthcare services.
                <br />
              </li>

              <li>
                Telehealth and offline services are available. <br />
              </li>

              <li>
                The UI of the site is responsive for mobile and desktop. status{" "}
                <br />
              </li>

              <li>
                It has some dynamic routes for services. <br />
              </li>

              <li>
                Google/email login authentication implemented.
                <br />
              </li>
            </ul>
          </p>
          <span>
            <a href="https://floral-care-hospital.web.app/">
              <button className="btn  button button-a button-big button-rouded">
                Live Site
              </button>
            </a>

            <a href="https://github.com/mhasancy/healthcare-related-website-mhasancy">
              <button className="btn  button button-a button-big button-rouded">
                GitHub Client
              </button>
            </a>
          </span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default FloralCare;
