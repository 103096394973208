import emailjs from "emailjs-com";
import React, { useRef } from "react";
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_izlm95j",
        "template_s1l2zdg",
        form.current,
        "user_c63thwuzOLl626FsCL2Gl"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact" className="parallax-about">
      <div className="title-box text-center">
        <h4 className="title-a">Contact</h4>
        <p className="subtitle-a">If you have any query, contact me.</p>
        <div className="line-mf"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-mf">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="title-box-2">
                      <h5 className="title-left">Send Me a Message</h5>
                    </div>
                    <div>
                      <form ref={form} onSubmit={sendEmail}>
                        <div className="messages"></div>

                        <div className="controls">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="user_name"
                                  className="form-control"
                                  placeholder="Your Name"
                                  required="required"
                                  data-error="Name is required*"
                                />
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="user_email"
                                  className="form-control"
                                  placeholder="Your Email"
                                  required="required"
                                  data-error="Valid email is required*"
                                />
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea
                                  name="message"
                                  className="form-control"
                                  placeholder="Your Message"
                                  rows="4"
                                  required="required"
                                  data-error="Please, leave me a message*"
                                ></textarea>
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>
                            <div className="col-md-12 text-center">
                              <input
                                type="submit"
                                className="button button-a button-big button-rouded"
                                value="Send"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="title-box-2 pt-4 pt-md-0">
                      <h5 className="title-left">Get in Touch</h5>
                    </div>
                    <div className="more-info">
                      <p className="lead"></p>
                      <ul className="list-ico">
                        <li>
                          <span>
                            <i class="bi bi-geo-alt-fill"></i>
                          </span>{" "}
                          Isdair, Narayanganj-1400, Bangladesh
                        </li>
                        <li>
                          <span>
                            <i class="bi bi-phone-fill"></i>
                          </span>{" "}
                          +88 01621 737078
                        </li>
                        <li>
                          <span>
                            <i class="bi bi-envelope-fill"></i>
                          </span>{" "}
                          mahadi.hasancy@gmail.com
                        </li>
                      </ul>
                    </div>
                    <div className="socials">
                      <ul>
                        <li>
                          <a href="/">
                            <span className="ico-circle">
                              <i class="bi bi-facebook"></i>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <span className="ico-circle">
                              <i class="bi bi-linkedin"></i>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <span className="ico-circle">
                              <i class="bi bi-twitter"></i>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <span className="ico-circle">
                              <i class="bi bi-github"></i>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
