import React from "react";

const About = () => {
  return (
    <section id="about" className="parallax-about">
      <div className="title-box text-center">
        <h3 className="title-a">About</h3>
        <p className="subtitle-a">How I've come here.</p>
        <div className="line-mf"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="box-shadow-full">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-6 col-md-5">
                      <div className="about-img">
                        <img
                          src="profile.jpg"
                          className="img-fluid rounded b-shadow-a"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-7">
                      <div className="about-info">
                        <br />
                        <p>
                          <span className="title-s">Name: </span>{" "}
                          <span>Mahadi Hasan</span>
                        </p>
                        <p>
                          <span className="title-s">Profile: </span>{" "}
                          <span>Front End Developer</span>
                        </p>
                        <p>
                          <span className="title-s">Email: </span>{" "}
                          <span>
                            <a
                              className="text-decoration-none"
                              href="mailto:mahadi.hasancy@gmail.com "
                            >
                              {" "}
                              mahadi.hasancy@gmail.com
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="skill-mf">
                    <br />
                    <br />
                    <p className="title-s">Skills</p>
                    <p>
                      <strong>Expertise</strong>
                    </p>
                    <span>HTML5, CSS3, JavaScript, ES6, React, Bootstrap5</span>
                    <br />
                    <br />
                    <p>
                      <strong>Comfortable</strong>
                    </p>
                    <span>
                      Tailwind, Node, Express, MongoDB, Firebase, React Hook
                      Form, Axios, React Helmet, Material-UI
                    </span>
                    <br />
                    <br />
                    <p>
                      <strong>Familiar</strong>
                    </p>
                    <span>
                      Typescript, Next Js, React Native, Sass, Styled Components
                    </span>
                    <br />
                    <br />
                    <p>
                      <strong>Tools</strong>
                    </p>
                    <span>GitHub, VS Code, Heroku, Yarn, Npm, Chrome Dev</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5 className="title-left">Why me?</h5>
                    </div>
                    <p className="lead">
                      I’ve always sought out opportunities and challenges that
                      are meaningful to me. I have had a keen interest in and
                      passion for web development since 2013, when I made my
                      first simple mobile website.
                    </p>
                    <p className="lead">
                      Since then, I have improved my skills not only on web
                      development but also in understanding industry needs. I've
                      never stopped feeding my passion for helping others and
                      solving problems. I've had professional experience in the
                      IT sector for more than 2 years and I also teach and guide
                      people on Microsoft Office.
                    </p>
                    <p className="lead">
                      As a web developer, I enjoy paying attention to detail and
                      making things, and using my mission-driven work ethic to
                      literally change the world. That's why I’m excited about
                      making a big impact at a high growth company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default About;
