import React from "react";
const GreenWatch = () => {
  return (
    <div className="container">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="row text-center">
        <div className="col-12 col-md-6">
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/0FXF5jd/Screenshot.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/GPj1zb0/Screenshot-1.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/LJty1fX/Screenshot-2.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/y8QMqDq/Screenshot-9.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <h2>
            Project Name: GREEN WATCH – A FULL STACK WATCH SELLING STORE PROJECT
          </h2>
          <h3>Details</h3>
          <p>
            This is a project for selling niche products like watches and it can
            be maintained easily with a bunch of cool functionality. <br />
            <ul className="text-start">
              <li>
                Firebase Authentication <br />
              </li>
              <li>
                Email signup/login, additional google login <br />
              </li>

              <li>
                Has respective dashboard for both admin and user <br />
              </li>
              <li>
                User can see their products and also can cancel. <br />
              </li>
              <li>
                Admin can manage all the orders, and can delete and can update
                the status. <br />
              </li>
              <li>
                Admin can add products and delete products <br />
              </li>
              <li>
                Users can provide reviews <br />
              </li>
              <li>
                Users see overview in dashboard home <br />
              </li>
              <li>
                Admin see overview in dashboard home <br />
              </li>
              <li>Admin can make another admin</li>
            </ul>
          </p>
          <span>
            <a href="https://green-watch.web.app/">
              <button className="btn  button button-a button-big button-rouded">
                Live Site
              </button>
            </a>

            <a href="https://github.com/mhasancy/niche-website-client-side-mhasancy">
              <button className="btn  button button-a button-big button-rouded">
                GitHub Client
              </button>
            </a>
            <a href="https://github.com/mhasancy/niche-website-server-side-mhasancy">
              <button className="btn  button button-a button-big button-rouded">
                GitHub Server
              </button>
            </a>
          </span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default GreenWatch;
