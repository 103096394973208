import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="copyright-box">
              <p className="copyright">
                &copy; Copyright <strong> mhasan.web.app</strong> 2021-22. All
                Rights Reserved.
              </p>
              <div className="credits">
                <a
                  className="text-decoration-none"
                  href="https://www.mhasan.web.app/"
                >
                  Mahadi Hasan
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
