import React from "react";

const TravelNow = () => {
  return (
    <div className="container">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="row text-center">
        <div className="col-12 col-md-6">
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/pwH0BVR/Screenshot-3.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/TrJXX0p/Screenshot-4.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/xYS6yyr/Screenshot-5.png"
              alt=""
            />
          </div>
          <br />
          <div>
            <img
              className="img-fluid"
              src="https://i.ibb.co/dGVLGXk/Screenshot-6.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <h2>
            Project Name: Travel Now – A Full-Stack Tourism Management Project
          </h2>
          <h3>Details</h3>
          <p>
            This is a project for booking a tour plan. <br />
            <ul className="text-start">
              <li>
                This is a tourism service project.
                <br />
              </li>
              <li>
                Anyone can book their required tour services.
                <br />
              </li>

              <li>
                User can see their services and also can delete. <br />
              </li>
              <li>
                Admin can manage all the orders, can delete and can update the
                status <br />
              </li>
              <li>
                Google login authentication implemented. <br />
              </li>
            </ul>
          </p>
          <span>
            <a href="https://travel-now-e6e38.web.app/">
              <button className="btn  button button-a button-big button-rouded">
                Live Site
              </button>
            </a>

            <a href="https://github.com/mhasancy/tourism-or-delivery-website-client-side-mhasancy">
              <button className="btn  button button-a button-big button-rouded">
                GitHub Client
              </button>
            </a>
            <a href="https://github.com/mhasancy/tourism-or-delivery-website-server-side-mhasancy">
              <button className="btn  button button-a button-big button-rouded">
                GitHub Server
              </button>
            </a>
          </span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default TravelNow;
