import { useEffect, useState } from "react";
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "./App.css";
import { ReactComponent as MySVG } from "./arrowUp.svg";
import Blogs from "./Pages/Blogs/Blogs";
import Footer from "./Pages/Footer/Footer";
import Home from "./Pages/Home/Home";
import Navbar from "./Pages/Navbar/Navbar";
import NotFound from "./Pages/NotFound/NotFound";
import FloralCare from "./Pages/Projects/FloralCare";
import GreenWatch from "./Pages/Projects/GreenWatch";
import TravelNow from "./Pages/Projects/TravelNow";
function App() {
  const [preLoading, setPreLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setPreLoading(false), 1000);
  }, []);

  return (
    <>
      {preLoading ? (
        <div style={{ marginTop: "40vh", marginLeft: "50vw" }}>
          <Hypnosis
            color="#00bfd8"
            width="100px"
            height="100px"
            duration="1s"
          />
        </div>
      ) : (
        <BrowserRouter>
          <Navbar></Navbar>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="blogs" element={<Blogs />} />
            <Route
              path="green-watch-full-stack-project"
              element={<GreenWatch />}
            />
            <Route
              path="travel-now-full-stack-project"
              element={<TravelNow />}
            />
            <Route
              path="floral-care-frontend-project"
              element={<FloralCare />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer></Footer>
          <ScrollToTop smooth component={<MySVG />} />
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
