import React from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <section id="projects" className="services-mf pt-5 route">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="title-box text-center">
              <h3 className="title-a">Projects</h3>
              <p className="subtitle-a">
                I have developed some projects using some of the modern web
                tools and technologies like React, Bootstrap, Firebase, Node,
                Express, MongoDB, Axios, Material UI, React Router, etc.
              </p>
              <div className="line-mf"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4" data-aos="zoom-in">
            <div className="project-box">
              <div className="service-ico">
                <img
                  className="img-fluid"
                  src="https://i.ibb.co/6YWCNKP/Screenshot-5.png"
                  alt=""
                  height="200"
                />
              </div>
              <div className="service-content">
                <h2 className="s-title">
                  Green Watch – A Full Stack Watch Selling Store Project
                </h2>
                <p className="s-description text-center">
                  This is a project for selling niche products like watches.
                  <br />
                  <br />
                  <br />
                  <Link to="/green-watch-full-stack-project">
                    <button className="button button-a button-big button-rouded">
                      Details
                    </button>
                  </Link>
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4" data-aos="zoom-in">
            <div className="project-box">
              <div className="service-ico">
                <img
                  className="img-fluid"
                  src="https://i.ibb.co/6yxFqsN/Screenshot-7.png"
                  alt=""
                  height="200"
                />
              </div>
              <div className="service-content">
                <h2 className="s-title">
                  Travel Now – A Full StackTourism Management Project
                </h2>
                <p className="s-description text-center">
                  This is a project planing tours and travels.
                  <br />
                  <br />
                  <br />
                  <Link to="/travel-now-full-stack-project">
                    <button className="button button-a button-big button-rouded">
                      Details
                    </button>
                  </Link>
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4" data-aos="zoom-in">
            <div className="project-box">
              <div className="service-ico">
                <img
                  className="img-fluid"
                  src="https://i.ibb.co/NLKtFz5/Screenshot-8.png"
                  alt=""
                  height="200"
                />
              </div>
              <div className="service-content">
                <h2 className="s-title">
                  Floral Care – A Frontend Hospital Management Project
                </h2>
                <p className="s-description text-center">
                  This is a project for hospital management.
                  <br />
                  <br />
                  <br />
                  <Link to="/floral-care-frontend-project">
                    <button className="button button-a button-big button-rouded">
                      Details
                    </button>
                  </Link>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
