import React from "react";
import Typewriter from "typewriter-effect";
const Intro = () => {
  return (
    <div
      id="home"
      className="intro route bg-image"
      style={{ backgroundImage: `url(/intro-bg.jpg)` }}
    >
      <div className="overlay-intro"></div>
      <div className="intro-content display-table">
        <div className="table-cell">
          <div className="container">
            <h1 className="intro-title mb-4">Mahadi Hasan</h1>
            <p className="intro-subtitle">
              <Typewriter
                options={{
                  strings: [
                    "Front End Developer",
                    "React Developer",
                    "Javascript Developer",
                    "Full Stack Developer",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </p>

            <a href="https://drive.google.com/file/d/18NnPwllk-DwMJuha_fX493K945senA1d/view?usp=sharing">
              <button className="btn  button button-a button-big button-rouded">
                Download Resume
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
